import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
function App() {
  return (
    <div className="App">
      <a
        href="https://www.linkedin.com/in/themaxsmith/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Maxwell Smith
      </a>
    </div>
  );
}

export default App;
